import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Select } from 'wwtc-sdk';
import './login.css';
import './common/button.css';

function Login({ languages }) {
    const history = useHistory();
    const [form, setForm] = useState({
        language: null,
        displayName: null,
    });
    const [info, setInfo] = useState({
        statusLogin: null,
        error: null,
    });

    const handleLanguage = (language) => {
        setForm({ ...form, language: language });
    };

    const handleLogin = (e) => {
        e.preventDefault();

        if (validateLogin()) {
            const { language, displayName } = form;

            setInfo({
                ...info,
                statusLogin: 'loading',
            });
            history.push(`/app`, {
                userName: displayName,
                language: language.value,
                gender: 'male',
                roomName: window.room,
                apiToken: window.apiToken,
                logoHeader: window.logoHeader,
                languagesPreTranslate: window.languagesPreTranslate,
                languages,
            });
        } else {
            setInfo({
                ...info,
                error: 'Please fill inputs to continue',
            });
        }
    };

    const validateLogin = () => {
        let valid = true;
        Object.keys(form).forEach((item) => {
            if (!form[item] || form[item] === '') valid = false;
        });
        return valid;
    };

    return (
        <div className="wrapper-login">
            <div className="wrapper-content">
                <div className="div-tests-button"></div>
                <div className="wrapper-login-modal">
                    <div className="wrapper-logo">
                        <img src={`${window.logoLogin}`} alt="WWTC" />
                    </div>
                    <form className="form-modal" onSubmit={handleLogin}>
                        <div className="title-welcome">
                            <h2>Welcome Moderator</h2>
                        </div>
                        <div className="wrapper-login-form">
                            <div className="login-inputs">
                                <Select
                                    handleChange={handleLanguage}
                                    placeholder="Select language"
                                    data={languages}
                                    value={form.language || ''}
                                    showLanguageServices={true}
                                />
                            </div>
                            <div className="login-inputs">
                                <input
                                    className="Input"
                                    type="text"
                                    placeholder="Display name"
                                    value={form.displayName || ''}
                                    onChange={(e) =>
                                        setForm({ ...form, displayName: e.target.value })
                                    }
                                />
                            </div>
                        </div>
                        <div className="wrapper-button-actions">
                            <button
                                onClick={handleLogin}
                                type="submit"
                                className="Button Button-primary Button-block"
                            >
                                {info.statusLogin === 'loading' ? (
                                    <img
                                        src={`${process.env.PUBLIC_URL}/oval.svg`}
                                        style={{ width: 14, height: 14 }}
                                        alt="loading"
                                    />
                                ) : (
                                    'connect now'
                                )}
                            </button>
                        </div>
                        <div className="error-message">
                            {info.error ? <p>{info.error}</p> : null}
                        </div>
                        <div className="wrapper-return-home d-flex justify-content-center">
                            <a className="link" href="/">
                                Return to Home Screen
                            </a>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
}

export default Login;
